import { Chapter } from '~/types/comic/chapter'
import { createSignal } from 'solid-js'
import { Database } from '~/types/supabase'
import { getChapter } from '~/services/database/chapters'
import { getPages } from '~/services/database/pages'
import { Page } from '~/types/comic/page'
import { getFileURL } from '~/services/database/file-storage'

type ChapterId = Database['public']['Tables']['chapters']['Row']['id']
const [chapterId, setChapterId] = createSignal<ChapterId>()
const [chapter, setChapter] = createSignal<Chapter>()

const getChapterData = async (id: ChapterId) => {
  const chapterData = await getChapter(id)
  const pagesData = await getPages(id)
  const pageQueries = pagesData.map(async (page) => {
    const url = await getFileURL({
      path: page.file,
      bucket: 'pages',
      // options: {
      //   transform: {
      //     quality: 65
      //   }
      // }
    })
    const result: Page = {
      id: page.id,
      index: page.index,
      type: page.type,
      url
    }
    return result
  })
  const pages: Page[] = await Promise.all(pageQueries)
  
  const chapter: Chapter = {
    id,
    comicId: chapterData.series_id,
    index: chapterData.index,
    title: chapterData.title,
    pages: {
      original: pages.filter(page => page.type === 'original').sort((a, b) => a.index - b.index),
      clean: pages.filter(page => page.type === 'clean').sort((a, b) => a.index - b.index)
    }
  }

  return chapter
}

export {
  chapterId,
  setChapterId,
  getChapterData,
  chapter,
  setChapter
}